import {Outlet, Navigate} from 'react-router-dom'
import {message} from "antd";
import Apiurl from "../ConstURL";
import {useEffect, useState} from "react";
import {useAuth} from "./Authcontext";


const PrivateRoutes1 = () => {
    const {user} = useAuth()
    return user ? <Outlet/> : <Navigate to="/login"/>
}


export default PrivateRoutes1
import React, {Component, useEffect, useImperativeHandle, useRef, useState} from "react";
import 'react-phone-number-input/style.css';
import {
    Button,
    Card,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Statistic,
    Tag,
    Modal,
    Spin, Skeleton, Drawer, Form, Input
} from "antd";
import Apiurl from "../ConstURL";
import {LoadingOutlined, DeleteOutlined} from '@ant-design/icons';
import {format} from 'date-fns';

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined, UserAddOutlined,
} from '@ant-design/icons';

import {useAuth} from "../funcs/Authcontext";
import PhoneInput from "react-phone-number-input";


export default function Mainpage() {
    const [accounts, set_accounts] = useState([])

    const [form] = Form.useForm()
    const [formSMS] = Form.useForm()
    const [inputSMS, set_inputSMS] = useState("")

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPopUpDelAllOpen, setisPopUpDelAllOpen] = useState(false);
    const [SessionsInfoLoad, setSessionsInfoLoad] = useState(true);
    const [sessionsInfo, setSessionsInfo] = useState([]);
    const [AccountIdsessioninfo, setAccountIdsessioninfo] = useState("");

    const onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
    };

    const MyInput = React.forwardRef((props, ref) => {
        const inputRef = useRef(null);

        useImperativeHandle(
            ref,
            () => {
                return inputRef.current.input;
            },
            []
        );
        return <Input {...props} ref={inputRef}/>;
    });
    const PhoneField = ({value, onChange, ...props}) => {
        return (
            <PhoneInput
                value={value}
                onChange={onChange}
                placeholder="Введите номер телефона"
                className="ant-input"
                defaultCountry="RU"
                international={true}
                limitMaxLength={true}
                inputComponent={MyInput}
                {...props}
            />
        );
    };

    const onFinish = (values) => {
        //console.log(values.mobile.slice(1))
        fetch(Apiurl + "/add-account?" + new URLSearchParams({
            phone: values.mobile.slice(1),
            name: values.accountname,
            password: values.pass
        }), {
            headers: {
                'x-api-key': localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.json()

            }).then(data => {
                console.log(data)
                if (data['status']) {

                    form.resetFields()
                    set_inputSMS(values.mobile.slice(1))
                    message.success("СМС отправлено!")


                } else {
                    message.error("Ошибка! " + data['message'])
                }
            }
        )


    };
    const onFinishSMS = (values) => {

        if (inputSMS !== " ") {

            fetch(Apiurl + "/verif-account?" + new URLSearchParams({
                phone: inputSMS,
                code: values.code,
            }), {
                headers: {
                    'x-api-key': localStorage.getItem("token"),
                }
            })
                .then(response => {
                    return response.json()

                }).then(data => {
                    console.log(data)
                    if (data['status']) {

                        formSMS.resetFields()
                        set_inputSMS("")
                        fetch(Apiurl + "/all-accounts", {
                            headers: {
                                'x-api-key': localStorage.getItem("token"),
                            }
                        })
                            .then(response => {
                                return response.json()
                            })
                            .then(data => {
                                if (data.status) {
                                    set_accounts(data.data)
                                }


                            })
                        message.success("Аккаунт авторизован!")

                        setOpenDrawer(false)

                    } else {
                        message.error("Ошибка! " + data['message'])
                    }
                }
            )

        }
    };


    const [openDrawer, setOpenDrawer] = useState(false);
    const showDrawer = () => {
        setOpenDrawer(true);
    };
    const onCloseDrawwer = () => {
        setOpenDrawer(false);
    };

    const Reauth = (e, account) => {

        fetch(Apiurl + "/reauth-account?id=" + account._id, {
            headers: {
                'x-api-key': localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status) {
                    set_inputSMS(account.phone_num)
                    showDrawer()
                } else {
                    message.error("Ошибка авторизации! " + data.message)
                }


            })


    };

    const showPopUp = (e) => {
        setisPopUpDelAllOpen(true);
    };
    const showModal = (e, account) => {
        setSessionsInfoLoad(true)
        setIsModalOpen(true);

        fetch(Apiurl + "/getallsessions-account?id=" + account._id, {
            headers: {
                'x-api-key': localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status) {
                    if (data.message === "bad session") {
                        setIsModalOpen(false);
                        message.error("Ошибка авторизации!")
                        fetch(Apiurl + "/all-accounts", {
                            headers: {
                                'x-api-key': localStorage.getItem("token"),
                            }
                        })
                            .then(response => {
                                return response.json()
                            })
                            .then(data => {
                                if (data.status) {
                                    set_accounts(data.data)
                                }


                            })
                    } else {
                        setSessionsInfo(data.data.authorizations)
                        setAccountIdsessioninfo(account._id)
                        console.log(data.data)
                        setSessionsInfoLoad(false)
                    }
                }


            })


    };


    const handleOk = () => {
        setIsModalOpen(false);
        setisPopUpDelAllOpen(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setisPopUpDelAllOpen(false)
    };


    useEffect(() => {
        document.body.style = 'background: black;';
        fetch(Apiurl + "/all-accounts", {
            headers: {
                'x-api-key': localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status) {
                    set_accounts(data.data)
                }


            })
    }, []);

    useEffect(() => {

        const timer = setInterval(() => {
            fetch(Apiurl + "/all-accounts", {
                headers: {
                    'x-api-key': localStorage.getItem("token"),
                }
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if (data.status) {
                        set_accounts(data.data)
                    }


                })
        }, 4000);

        // очистка интервала
        return () => clearInterval(timer);
    },[accounts]);

    function DeleteElementYesorNo(id) {

        const idx = sessionsInfo.findIndex((el) => el.hash === id)
        let newusers = sessionsInfo.slice()
        if (typeof newusers[idx].stopsesion !== 'undefined') {
            newusers[idx].stopsesion = !newusers[idx].stopsesion

        } else {
            newusers[idx].stopsesion = true;
        }
        console.log(newusers[idx].stopsesion)
        setSessionsInfo(newusers)

    }

    function DeleteElementYess(id) {
        const idx = sessionsInfo.findIndex((el) => el.hash === id)
        let newusers = sessionsInfo.slice()
        newusers[idx].stopsesion = !newusers[idx].stopsesion

        //setSessionsInfo(newusers)

        fetch(Apiurl + "/deauth-account?" + new URLSearchParams({
            id: AccountIdsessioninfo,
            hash: id
        }), {
            headers: {
                'x-api-key': localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status) {
                    newusers.splice(idx, 1)
                    setSessionsInfo(newusers)
                    message.success("Сессия завершена!")
                } else {
                    setSessionsInfo(newusers)
                    if (data.message === "auth not 24h ago") {

                        message.error("С момента добавления аккаунта не прошло 24 часа!")
                    } else {
                        message.error("Ошибка! " + data.message)
                    }
                }

            })


    }

    function DeleteAllSessions() {
        let sessions = sessionsInfo.slice()
        //setSessionsInfo(newusers)
        setIsModalOpen(false);
        for (var index = 0; index < sessions.length; ++index) {
            if (sessions[index].hash !== "0") {
                fetch(Apiurl + "/deauth-account?" + new URLSearchParams({
                    id: AccountIdsessioninfo,
                    hash: sessions[index].hash
                }), {
                    headers: {
                        'x-api-key': localStorage.getItem("token"),
                    }
                })
                    .then(response => {
                        return response.json()
                    })
                    .then(data => {
                        if (data.status) {

                            message.success("Сессия " + sessions[index].device_model + "завершена!")
                        } else {

                            if (data.message === "auth not 24h ago") {

                                message.error("С момента добавления аккаунта не прошло 24 часа!")
                            } else {
                                message.error("Ошибка! " + data.message)
                            }
                        }

                    })
            }
        }


    }
    function ChangeTgName(e,AccountId){
        fetch(Apiurl + "/change-tgname?" + new URLSearchParams({
            iduser: AccountId,
            tg_name: e.target.value
        }), {
            headers: {
                'x-api-key': localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {


            })

    }
    function DeleteAllSessionsAllUsers() {
        setisPopUpDelAllOpen(false)
        fetch(Apiurl + "/deauth-all-accounts", {
            headers: {
                'x-api-key': localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status) {
                    message.success("Все пользователи отключены ")
                }


            })


    }
    return (
        <Space style={{alignItems: "center",width:"100%"}} type="flex" size='large' justify="center" align="middle"
               direction="vertical">
            <Flex vertical={true} justify={"center"} align="middle"
                  style={{paddingTop: "25px", alignItems: "flex-end"}}>

                <Space direction="vertical"  type="flex" justify="center"
                       align="middle">
                    <Button icon={<UserAddOutlined/>} type={"primary"} onClick={(e) => showDrawer(e)}>Добавить
                        аккаунт</Button>
                </Space>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px", alignItems: "center"}}>

                    {accounts.map((account, index) => (
                        <Card key={account._id} style={{width: "80vw"}} title={account.name}
                              extra={account.authed ? <Tag color={"success"}>Авторизован</Tag> :
                                  <Tag color={"error"}>Не авторизован</Tag>}>
                            <Flex vertical={true} gap={15}>
                                <div>Номер телефона: {account.phone_num}</div>

                                {account.time_auth ? <div>Дата
                                    авторизации: {format(new Date(account.time_auth), 'dd.MM.yyyy HH:mm:ss')}</div> : ""}
                                {account.time_add ? <div>Дата
                                    добавления: {format(new Date(account.time_add), 'dd.MM.yyyy HH:mm:ss')}</div> : ""}
                                <Input defaultValue={account.tgname ? account.tgname : ""} onChange={(e)=>ChangeTgName(e,account._id)}></Input>
                                {account.authed ? <div><Button type={"primary"} onClick={(e) => showModal(e, account)}>Активные
                                        сеансы</Button></div> :
                                    <div><Button danger type={"primary"}
                                                 onClick={(e) => Reauth(e, account)}>Переавторизоваться</Button></div>}

                            </Flex>
                        </Card>

                    ))}


                </Space>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{width: "100%", paddingTop: "45px",paddingBottom: "45px", alignItems: "center"}}>

                    <Button icon={<DeleteOutlined/>} danger={true} type={"primary"} onClick={(e) => showPopUp(e)}>Отключить
                        всех пользователей</Button>
                </Space>

            </Flex>
            <Modal style={{marginTop: "25px", marginBottom: "25px"}} title="Активные сеансы" centered={true}
                   open={isModalOpen} onOk={handleOk} footer={null}
                   onCancel={handleCancel}>
                {SessionsInfoLoad ?
                    <div>
                        <Skeleton active/>
                        <Skeleton active/>
                        <Skeleton active/>
                    </div> :

                    <Space style={{width: "100%", alignItems: "center"}} type="flex" size='large' justify="center"
                           align="middle"
                           direction="vertical">

                        {sessionsInfo.map((session, index) => (
                            <div>
                                <Card title={session.device_model}
                                      extra={session.hash === "0" ? <Tag color={"success"}>Бот</Tag> : ""}
                                      actions={[<div>{session['stopsesion'] ?
                                          <Flex gap={100} justify={"center"}><Space><Button shape={"circle"}
                                                                                            onClick={(e) => DeleteElementYess(session.hash)}
                                                                                            icon={
                                                                                                <CheckOutlined/>}></Button>
                                              <Button type={"primary"} shape={"circle"} icon={<CloseOutlined/>} danger
                                                      onClick={(e) => DeleteElementYesorNo(session.hash)}></Button></Space></Flex> :
                                          <Button danger onClick={(e) => DeleteElementYesorNo(session.hash)}
                                                  type={"primary"}>Завершить сеанс</Button>}</div>]}>
                                    <Flex vertical={true} gap={10}>
                                        <div>Название приложения: {session.app_name}</div>
                                        <div>Платформа: {session.platform}</div>
                                        <div>Модель: {session.device_model}</div>
                                        <div>Версия системы: {session.system_version}</div>
                                        <div>Официально приложение: {session.official_app ? "Да" : "Нет"}</div>
                                        <div>Hash: {session.hash}</div>
                                        <div>IP: {session.ip}</div>
                                        <div>Геопозиция: {session.country}</div>
                                        {session.date_active ? <div>Последняя
                                            активность: {format(new Date(session.date_active), 'dd.MM.yyyy HH:mm:ss')}</div> : ""}
                                        {session.date_created ? <div>Дата
                                            авторизации: {format(new Date(session.date_created), 'dd.MM.yyyy HH:mm:ss')}</div> : ""}
                                    </Flex>
                                </Card>


                            </div>


                        ))}

                        <Button style={{marginTop: "50px"}} onClick={(e) => DeleteAllSessions()}>Завершить все
                            сеансы</Button>
                    </Space>


                }


            </Modal>

            <Modal style={{marginTop: "25px", marginBottom: "25px"}} title="Отключить всех пользователей?"
                   centered={true} open={isPopUpDelAllOpen} onOk={handleOk} footer={null}
                   onCancel={handleCancel}>

                <Space style={{paddingTop:"45px",justifyContent:"space-around",paddingBottom:"45px",width:"100%"}} type="flex" size='large' justify="center" direction={"horizontal"}>
                    <Button size={"large"} onClick={(e)=>DeleteAllSessionsAllUsers(e)} type={"primary"} danger={true}>Да</Button>
                    <Button size={"large"}  onClick={handleCancel} type={"primary"}>Нет</Button>
                </Space>

            </Modal>
            <Drawer
                height={"500"}
                title="Добавление аккаунта"
                placement={"bottom"}
                closable={false}
                onClose={onCloseDrawwer}
                open={openDrawer}
                key={"bottom"}
            >

                {inputSMS === "" ? <Form

                        name="basic"
                        form={form}
                        style={{
                            verticalAlign: 'middle',
                            maxWidth: "90vw",
                        }}
                        initialValues={{
                            remember: false,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Название аккаунта"
                            name="accountname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите название аккаунта!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Телефон"
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите номер телефона!',
                                },
                            ]}
                        >
                            <PhoneField/>
                        </Form.Item>

                        <Form.Item
                            label="Пароль"
                            name="pass"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите пароль!',
                                },
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>


                        <Form.Item
                            style={{alignItems: "center", display: "flex", justifyContent: "center"}}>
                            <Button type="primary" htmlType="submit">
                                Получить код подтверждения
                            </Button>
                        </Form.Item>
                    </Form> :
                    <Form
                        name="basic"
                        form={formSMS}
                        style={{
                            verticalAlign: 'middle',
                            maxWidth: "90vw",
                        }}
                        initialValues={{
                            remember: false,
                        }}
                        onFinish={onFinishSMS}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off">
                        <Form.Item
                            label="Проверочный код"
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите название аккаунта!',
                                },
                            ]}
                        >
                            <Input pattern={"[0-9]*"}/>
                        </Form.Item>

                        <Form.Item
                            style={{alignItems: "center", display: "flex", justifyContent: "center"}}
                        >
                            <Button type="primary" htmlType="submit">
                                Ввести код подтверждения
                            </Button>
                        </Form.Item>
                    </Form>

                }

            </Drawer>

        </Space>
    );

}